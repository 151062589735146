import React, { Component } from 'react';
import logo from './logo.svg';
import { Link } from 'react-router-dom';

const imgStyle = {
  position: 'relative',
  top: '10px'
};

class Navbar extends Component {
  render() {
    return (
      <div>
        <nav className="light-blue lighten-1" role="navigation">
          <div className="nav-wrapper container">
            <a href="/" className="brand-logo">
              <img src={logo} alt="logo" style={imgStyle} />
            </a>
            <ul className="right hide-on-med-and-down">
              {/* <li>
                <Link to="/">资讯</Link>
              </li> */}
              {/* <li>
                <a href="https://community.iotex.io">论坛</a>
              </li> */}
              {/* <li>
                <Link to="/profit">收益表</Link>
              </li> */}
              <li>
                <Link to="/bid-history">VITA</Link>
              </li>
              <li>
                <a className="dropdown-trigger" href="#!" data-target="iopay">
                  ioPay
                  <i className="material-icons right" style={{ marginLeft: 0 }}>
                    arrow_drop_down
                  </i>
                </a>
              </li>
              <li>
                <a
                  className="waves-effect waves-light btn"
                  href="https://member.iotex.io/delegate/5c736ab52d01e727d88b9de9"
                >
                  投票赚收益
                </a>
              </li>
            </ul>

            <ul id="nav-mobile" className="sidenav">
              {/* <li>
                <Link to="/">资讯</Link>
              </li> */}
              {/* <li>
                <a href="https://community.iotex.io">论坛</a>
              </li> */}
              {/* <li>
                <Link to="/profit">收益表</Link>
              </li> */}
              <li>
                <Link to="/bid-history">VITA</Link>
              </li>
              <li>
                <a
                  className="dropdown-trigger"
                  href="#!"
                  data-target="iopay-mobile"
                >
                  ioPay
                  <i className="material-icons right" style={{ marginLeft: 0 }}>
                    arrow_drop_down
                  </i>
                </a>
              </li>
              <li>
                <a
                  className="waves-effect waves-light btn-small"
                  href="https://member.iotex.io/delegate/5c736ab52d01e727d88b9de9"
                >
                  投票赚收益
                </a>
              </li>
            </ul>

            <ul id="iopay" className="dropdown-content">
              <li>
                <a href="itms-apps://itunes.apple.com/app/id1478086371">IOS</a>
              </li>
              <li>
                <a href="http://iopay.longz.club">Android</a>
              </li>
            </ul>

            <ul id="iopay-mobile" className="dropdown-content">
              <li>
                <a href="itms-apps://itunes.apple.com/app/id1478086371">IOS</a>
              </li>
              <li>
                <a href="http://iopay.longz.club">Android</a>
              </li>
            </ul>

            <a href="#!" data-target="nav-mobile" className="sidenav-trigger">
              <i className="material-icons">menu</i>
            </a>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
