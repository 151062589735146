import React, { Component } from 'react';

class VitaBid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      round: 0,
      iotexBid: 0,
      vitaAmount: 0,
      pro: 0
    };
  }

  render() {
    return (
      <div className="row teal lighten-1 white-text">
        <div className="col s6 m3">
          <p>轮次: {this.state.round}</p>
        </div>
        <div className="col s6 m3">
          <p>出价: {this.state.iotexBid} IOTX</p>
        </div>
        <div className="col s6 m3">
          <p>VITA池: {this.state.vitaAmount} VITA</p>
        </div>
        <div className="col s6 m3">
          <p>兑换比: {this.state.pro.toFixed(0)} VITA/IOTX</p>
        </div>
        <div className="col s6 m3">
          <p>平均: {this.state.avg} VITA/IOTX</p>
        </div>
      </div>
    );
  }

  componentDidMount() {
    fetch('//api.longz.club/vita-bid')
      .then(function(response) {
        return response.json();
      })
      .then(data => {
        this.setState(state => ({
          round: data.Round,
          iotexBid: data.IotexBid,
          vitaAmount: data.VitaAmount,
          pro: data.VitaAmount / data.IotexBid,
          avg: data.Avg
        }));
      });
  }
}

export default VitaBid;
