import React, { Component } from 'react';
import Navbar from './Navbar';
import Content from './Content';
import './App.css';
import Footer from './Footer';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import Profit from './Profit';
import BIdHistory from './BidHistory';

const M = window.M;

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <header>
            <Navbar />
          </header>
          <Route exact path="/" component={Content} />
          {/* <Route path="/profit" component={Profit} /> */}
          <Route path="/bid-history" component={BIdHistory} />
          <Footer />
        </div>
      </Router>
    );
  }

  componentDidMount() {
    M.AutoInit();
  }
}

export default App;
