import React, { Component } from 'react';
import countdown from './countdown.jpg';
import activity from './activity.jpg';
import wechatjpg from './WechatIMG5.jpeg';
import './Content.css';
import VitaBid from './VitaBid';

const M = window.M;

class Content extends Component {
  render() {
    return (
      <div id="content" className="container">
        <VitaBid />
        <div className="recommend-banners">
          <div className="row">
            <div className="main-banner hoverable col s12 m6">
              <p className="title white-text">
                万物可信互联的起源： 一文彻底读懂IoTeX主网上线！
              </p>
              {/* eslint-disable-next-line */}
              <a
                className="link"
                href="https://mp.weixin.qq.com/s/AjJg1lfytYcqHLGzhr4ZkA"
              />
            </div>
            <div className="secondary-banner col s12 m6">
              <div
                className="item hoverable"
                style={{ backgroundImage: `url(${countdown})` }}
              >
                <p className="title white-text">IoTeX主网上线 倒计时2天！</p>
                {/* eslint-disable-next-line */}
                <a className="link" href="#" />
              </div>
              <div
                className="item hoverable"
                style={{ backgroundImage: `url(${activity})` }}
              >
                <p className="title white-text">
                  普大喜奔IoTeX主网上线！拍短视频，赢IOTX！
                </p>
                {/* eslint-disable-next-line */}
                <a
                  className="link"
                  href="https://mp.weixin.qq.com/s/wWYZyViWPG5XKkB7MrVQog"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <ul className="collapsible expandable no-autoinit">
            <li className="active">
              <div className="collapsible-header">
                <i className="material-icons">person</i>使用者
              </div>
              <div className="collapsible-body">
                <div className="collection">
                  <a
                    href="https://mp.weixin.qq.com/s/U1I96R9i27irUgQcqgGWcw"
                    className="collection-item"
                  >
                    IoTeX第三期“你问我答”活动
                    <i className="secondary-content material-icons">send</i>
                  </a>
                  <a
                    href="https://mp.weixin.qq.com/s/0R2iDBvNtW3Kxz7arMSthQ"
                    className="collection-item"
                  >
                    币安官方钱包TrustWallet正式上线IoTeX质押/投票dApp！
                    <i className="secondary-content material-icons">send</i>
                  </a>
                  <a
                    href="https://mp.weixin.qq.com/s/cR-MIVcli1u9F5mT0e1JRg"
                    className="collection-item"
                  >
                    IoTeX通证分配计划更新
                    <i className="secondary-content material-icons">send</i>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                <i className="material-icons">build</i>开发者
              </div>
              <div className="collapsible-body">
                <div className="collection">
                  <a
                    href="https://github.com/iotexproject/iotex-bootstrap/blob/master/launch_playbook_mainnet.md"
                    className="collection-item"
                  >
                    IoTeX主网运行手册
                    <i className="secondary-content material-icons">send</i>
                  </a>
                  <a
                    href="https://mp.weixin.qq.com/s/DQlRT-ToVacQxh7_i-L9Qw"
                    className="collection-item"
                  >
                    DDoS攻击赏金计划
                    <i className="secondary-content material-icons">send</i>
                  </a>
                  <a href="https://docs.iotex.io/" className="collection-item">
                    IoTeX项目文档
                    <i className="secondary-content material-icons">send</i>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="section">
          <div className="row">
            <div className="col s12">
              <ul className="tabs">
                <li className="tab col s4 m2">
                  <a className="active" href="#tab-all">
                    全部
                  </a>
                </li>
                <li className="tab col s4 m2">
                  <a href="#test3">IoTeX生态</a>
                </li>
                <li className="tab col s4 m2">
                  <a href="#news">博客</a>
                </li>
                <li className="tab col s4 m2">
                  <a href="#test5">技术讨论</a>
                </li>
              </ul>
            </div>
            <div id="tab-all" className="col s12">
              <ul className="collection">
                <li className="collection-item">
                  <div className="post-item row">
                    <div
                      className="post-cover col s12 m3"
                      style={{ backgroundImage: `url(${wechatjpg})` }}
                    />
                    <div className="post-info col s12 m9">
                      <a
                        className="title"
                        href="https://mp.weixin.qq.com/s/AjJg1lfytYcqHLGzhr4ZkA"
                      >
                        万物可信互联的起源： 一文彻底读懂IoTeX主网上线！
                      </a>
                      <p className="description truncate">
                        今天，我们很高兴地向IoTeX宣布，IoTeX公链主网Alpha，即将于4月22日正式上线！
                      </p>
                      <div className="meta">
                        <span>IoTeX</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="collection-item">
                  <div className="post-item row">
                    <div
                      className="post-cover col s12 m3"
                      style={{ backgroundImage: `url(${activity})` }}
                    />
                    <div className="post-info col s12 m9">
                      <a
                        className="title"
                        href="https://mp.weixin.qq.com/s/wWYZyViWPG5XKkB7MrVQog"
                      >
                        普大喜奔IoTeX主网上线！拍短视频，赢IOTX！
                      </a>
                      <p className="description truncate">
                        亲爱的成员们，IoTeX主网Alpha即将正式上线！为了能和IoTeX全球成员共同庆祝这一重要的里程碑，
                        我们在此推出主网上线前最后一个活动：普大喜奔IoTeX主网上线！拍短视频，赢IOTX！
                      </p>
                      <div className="meta">
                        <span> IoTeX</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div id="test3" className="col s12" />
            <div id="news" className="col s12">
              <ul className="collection">
                <li className="collection-item">
                  <div className="post-item row">
                    <div
                      className="post-cover col s12 m3"
                      style={{ backgroundImage: `url(${wechatjpg})` }}
                    />
                    <div className="post-info col s12 m9">
                      <a
                        className="title"
                        href="https://mp.weixin.qq.com/s/AjJg1lfytYcqHLGzhr4ZkA"
                      >
                        万物可信互联的起源： 一文彻底读懂IoTeX主网上线！
                      </a>
                      <p className="description truncate">
                        今天，我们很高兴地向IoTeX宣布，IoTeX公链主网Alpha，即将于4月22日正式上线！
                      </p>
                      <div className="meta">
                        <span>IoTeX</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="collection-item">
                  <div className="post-item row">
                    <div
                      className="post-cover col s12 m3"
                      style={{ backgroundImage: `url(${activity})` }}
                    />
                    <div className="post-info col s12 m9">
                      <a
                        className="title"
                        href="https://mp.weixin.qq.com/s/wWYZyViWPG5XKkB7MrVQog"
                      >
                        普大喜奔IoTeX主网上线！拍短视频，赢IOTX！
                      </a>
                      <p className="description truncate">
                        亲爱的成员们，IoTeX主网Alpha即将正式上线！为了能和IoTeX全球成员共同庆祝这一重要的里程碑，
                        我们在此推出主网上线前最后一个活动：普大喜奔IoTeX主网上线！拍短视频，赢IOTX！
                      </p>
                      <div className="meta">
                        <span> IoTeX</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div id="test5" className="col s12" />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    var elem = document.querySelector('.collapsible.expandable');
    M.Collapsible.init(elem, { accordion: false });
  }
}

export default Content;
