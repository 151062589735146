import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="page-footer light-blue lighten-1">
        <div className="container">
          <div className="row">
            <div className="col l6 s12">
              <h5 className="white-text">龙猪博客</h5>
              <p className="grey-text text-lighten-4">
                致力于建设一个技术爱好者的乐园、助力Dapp开发者的梦想小窝，丰富IoTeX的应用生态、帮助用户寻找适合Dapp的全民博客。
              </p>
            </div>
            <div className="col l4 offset-l2 s12">
              <h5 className="white-text">相关链接</h5>
              <ul>
                <li>
                  <a
                    className="grey-text text-lighten-3"
                    href="https://member.iotex.io/delegate/5c736ab52d01e727d88b9de9"
                  >
                    投票赚收益
                  </a>
                </li>
                <li>
                  <a
                    className="grey-text text-lighten-3"
                    href="https://iotex.io/"
                  >
                    IoTeX官网
                  </a>
                </li>
                <li>
                  <a
                    className="grey-text text-lighten-3"
                    href="https://www.iotex.community/vita"
                  >
                    VITA官网
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            © 2019 Copyright{' '}
            <a className="white-text" href="https://beian.miit.gov.cn/">
              {' '}
              浙ICP备19011201号-1{' '}
            </a>
            <span className="right">
              Built with{' '}
              <i className="test-center materialize-red-text tiny material-icons">
                favorite
              </i>{' '}
              by Poppo
            </span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
