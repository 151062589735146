import React, { Component } from 'react';
import VitaBid from './VitaBid';

class BIdHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };

    this.clacProfit();
  }

  render() {
    return (
      <div className="container">
        <VitaBid />
        <div style={{ minHeight: '460px' }}>
          <div className="section">
            <BidHistoryTable items={this.state.items} />
          </div>
        </div>
      </div>
    );
  }

  clacProfit() {
    fetch('//api.longz.club/vita-bid-history')
      .then(function(response) {
        return response.json();
      })
      .then(data => {
        this.setState(state => ({
          items: data
        }));
      });
  }
}

class BidHistoryTable extends Component {
  render() {
    return (
      <table className="striped">
        <thead>
          <tr>
            <th>轮次</th>
            <th>出价(IOTX)</th>
            <th>VITA池(VITA)</th>
            <th>兑换比(VITA/IOTX)</th>
          </tr>
        </thead>

        <tbody>
          {this.props.items.map(item => (
            <tr key={item.Round}>
              <td>{item.Round}</td>
              <td>{item.IotexBid}</td>
              <td>{item.VitaAmount}</td>
              <td>{(item.VitaAmount / item.IotexBid).toFixed(0)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default BIdHistory;
